<template>
  <div id="VacationDaysAdjustmentRequestView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>{{ isNewRequestPage ? 'adjust vacation days': 'view vacation days adjustment' }}</h4>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
        <ul class="error">
          <li v-for="error in errors">
            {{ error.message }}
          </li>
        </ul>
        <fieldset v-if="!isCompleted" v-bind:disabled="disableFields">
          <employee-autocomplete v-if="isNewRequestPage" v-show="currentUserCanRaiseRequestForOthers" :items="filteredSearchedEmployees" id="emp_search" :sData="employeeInfo.fullName" v-bind:isShown="true" isRequired />
          <div>
            <employee-info-display v-if="employeeInfo.employeeId" :employeeInfo="employeeInfo" :vacationMaster="vacationMaster" :requestCanBeRaisedForEmployee="leaveRequestCanBeRaisedForEmployee" :additionalData="additionalData"></employee-info-display>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="row" v-if="requestCanBeRaisedForEmployee">
      <div class="col">
        <form id="entryForm" onsubmit="event.preventDefault();" novalidate>
          <fieldset v-bind:disabled="isSavingRequest">
            <div class="form-group row mt-4">
              <label class="col-md-3"><span class="font-weight-bold text-danger">*</span>adjustment tyoe</label>
              <select class="form-control col-md-3" v-model="request.changeRequestType" required>
                <option value="" disabled>--Select adjustment type--</option>
                <option value="5">Add vacation days</option>
                <option value="6">Subtract vacation days</option>
              </select>
            </div>
            <div class="form-group row mt-4">
              <label class="col-md-3"><span class="font-weight-bold text-danger">*</span>no. of days</label>
              <input type="number" class="form-control col-md-1" v-if="request.changeRequestType == 6" v-model="request.numberOfDays" required min="1" :max="vacationMaster.unScheduledVacationDays" />
              <input type="number" class="form-control col-md-1" v-else v-model="request.numberOfDays" required min="1" />
              <span v-if="request.numberOfDays > vacationMaster.unScheduledVacationDays && request.changeRequestType == 6" class="text-danger font-weight-bold">{{ '( max: ' + vacationMaster.unScheduledVacationDays + ' )'  }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-md-3">remarks</label>
              <textarea class="form-control col-md-5" rows="4" v-model="request.reasonForChange" v-bind:disabled="disableFields" required></textarea>
            </div>
            <div class="col-md-12 text-center">
              <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isSavingRequest }" @click="submitRequest" v-bind:disabled="isSavingRequest">{{ !request.changeRequestType ? 'adjust' : request.changeRequestType == 5 ? 'add' : 'subtract' }} vacation days<span class="spinner"></span></button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .action-btn {
    cursor: pointer;
    font-size: 30px;
  }

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }
</style>

<script>
  import { mapState } from 'vuex'

  import Enums from '@/utils/enums'
  import ErrorMessages from '@/utils/errors/messages'

  import { employeeService, vacationDaysAdjustmentRequestService, vacationMasterService } from '@/services'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'

  import EmployeeInfoDisplay from '@/components/vacation/cashInLieu/EmployeeInfoDisplay'

  import GeneralMixin from '@/mixins/GeneralMixin'
  import AccessControlMixin from '@/mixins/AccessControlMixin'
  import LeaveRequestMixin from '@/mixins/leaveRequest'
  import LeaveRequestActionsMixin from '@/mixins/leaveRequest/requestActions'
  import VacationMasterMixin from '@/mixins/leaveRequest/vacationMaster'


  export default {
    name: 'VacationDaysAdjustmentRequestView',
    components: {
      EmployeeInfoDisplay
    },
    mixins: [GeneralMixin, AccessControlMixin, LeaveRequestMixin, LeaveRequestActionsMixin, VacationMasterMixin],
    data: () => ({
      request: {
        id: null,
        vacationYear: null,
        changeRequestType: '',
        numberOfDays: null,
        reasonForChange: '',
        requestType: '',
        requestActions: []
      },
      vacationMaster: {
        id: null,
        vacationYear: null,
        vacationDaysDue: 0,
        travelDays: 0,
        vacationAndTravelDays: 0,
        carriedOverDays: 0,
        unScheduledVacationDays: 0,
        unscheduledRnRDays: 0,
        buyOutDays: 0,
        version: null
      },
      employeeInfo: {
        employeeId: '',
        employeeNo: '',
        fullName: '',
        employmentDate: '',
        sbuStartDate: '',
        locationName: '',
        locationId: '',
        workScheduleName: '',
        workScheduleId: '',
        employeeTypeId: '',
        employeeTypeName: '',
        supervisorName: '',
        payrollCountryId: null,
        payrollCountry: null,
        alternateSupervisorName: '',
        isRegularEmployee: false,
        isExpatriateEmployee: false,
        isResidentExpatriateEmployee: false,
        isFieldEmployee: false,
        isOnForeignService: false
      },
      additionalData: {
        vacationYears: [],
        startingUscheduledVacationDays: 0
      }
    }),
    computed: {
      ...mapState({
        appSettings: state => state.applicationSettingsModule.appSettings,
      }),
      filteredSearchedEmployees() {
        if (this.searchedEmployees && this.searchedEmployees.length) {
          return this.searchedEmployees.filter(employee => (employee.isRegularEmployee || employee.isExpatriateEmployee) && employee.employeeId.toLowerCase() !== this.currentUser.id.toLowerCase())
        }
        return []
      },
      requestCanBeRaisedForEmployee() {
        // An employee has been selected
        if (this.employeeInfo.employeeId) {
          // Only regular field employees not on foreign service can raise a field vacation option scheduling request
          if (this.employeeInfo.employeeId.toLowerCase() == this.currentUser.employeeId.toLowerCase()) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestForSelf, this.request.requestType)
            return false
          }
          if (this.employeeInfo.isRegularEmployee || this.employeeInfo.isExpatriateEmployee) {
            if (this.employeeInfo.isOnForeignService) {
              this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToEmployeeBeingOnForeignService, this.request.requestType)
              return false
            }
            if (!this.vacationMaster.id) {
              this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.VacationMasterNotYetGenerated, this.request.requestType)
              return false;
            }
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
            return true
          }
          else {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToEmployeeTypeOrScheduleOrLocation, this.request.requestType)
            return false
          }
        }
        else {
          this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
          return false
        }
      },
    },
    watch: {
      'vacationMaster.vacationYear': {
        immediate: false,
        handler(newValue, oldValue) {
          if (this.employeeInfo.employeeId) {
            this.fetchEmployeeData(this.employeeInfo.employeeId)
          }
        }
      },
      changeRequestType: {
        immediate: false,
        handler(newValue, oldValue) {
          if (changeRequestType == 5) {
            this.request.requestType = Enums.RequestType.AdjustmentAddition.value
          }
          else {
            this.request.requestType = Enums.RequestType.AdjustmentRemoval.value
          }
        }
      }
    },
    methods: {
      resetOtherData() {
        this.resetEmployeeData()
        this.employeeInfo.employmentDate = ''
        this.employeeInfo.sbuStartDate = ''
        this.employeeInfo.payrollCountryId = ''
        this.employeeInfo.payrollCountry = ''
        this.vacationMaster.id = null
        this.vacationMaster.vacationDaysDue = 0
        this.vacationMaster.travelDays = 0
        this.vacationMaster.vacationAndTravelDays = 0
        this.vacationMaster.carriedOverDays = 0
        this.vacationMaster.unScheduledVacationDays = 0
        this.vacationMaster.unscheduledRnRDays = 0
        this.vacationMaster.buyOutDays = 0
        this.vacationMaster.fieldVacationOption = ''
        this.vacationMaster.version = null
      },
      fetchEmployeeData(data) {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        let tasks = []
        let employeeId = data
        if (typeof data === 'object') {
          employeeId = data.employeeId
        }
        tasks.push(employeeService.getEmployeeWithWorkType(employeeId, true))
        tasks.push(vacationMasterService.getVacationMasterFor(employeeId, self.vacationMaster.vacationYear))
        Promise.all(tasks).then((results) => {
          self.resetData()
          const employee = results[0].data
          var vacationMaster = results[1]
          self.setData(employee, vacationMaster)
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      setData(employee, vacationMaster) {
        this.setEmployeeData(employee)
        this.employeeInfo.employmentDate = employee.employmentDate
        this.employeeInfo.sbuStartDate = employee.sbuStartDate
        this.employeeInfo.payrollCountryId = employee.payrollCountryId
        this.employeeInfo.payrollCountry = employee.payrollCountry
        this.employeeInfo.isShiftEmployee = employee.isShiftEmployee
        this.employeeInfo.isExpatriateEmployee = employee.isExpatriateEmployee
        this.employeeInfo.isResidentExpatriateEmployee = employee.isResidentExpatriateEmployee
        if (vacationMaster) {
          this.setVacationData(vacationMaster)
        }
      },
      setVacationData(vacationMaster) {
        this.vacationMaster.id = vacationMaster.id
        this.vacationMaster.vacationDaysDue = vacationMaster.vacationDaysDue
        this.vacationMaster.travelDays = vacationMaster.travelDays
        this.vacationMaster.vacationAndTravelDays = vacationMaster.vacationAndTravelDays
        this.vacationMaster.carriedOverDays = vacationMaster.carriedOverDays
        this.vacationMaster.unScheduledVacationDays = this.additionalData.startingUscheduledVacationDays = vacationMaster.unscheduledVacationDays
        this.vacationMaster.unscheduledRnRDays = vacationMaster.unscheduledRnRDays
      },
      submitRequest() {
        const self = this
        self.validateRequest().then((validationResult) => {
          if (validationResult) {
            self.closeMessage()
            self.isSavingRequest = true
            let request = { ...self.request }
            request.employeeId = self.employeeInfo.employeeId
            request.vacationYear = self.vacationMaster.vacationYear
            vacationDaysAdjustmentRequestService.schedule(request).then((result) => {
              self.resetData()
              self.request.changeRequestType = ''
              self.request.numberOfDays = null
              self.request.reasonForChange = ''
              self.request.requestType = ''
              self.showSuccessMessage('request submitted successfully')
              self.isSavingRequest = false
            }).catch((error) => {
              console.log(error)
              self.showErrorMessage(error)
            }).finally(() => {
              self.isSavingRequest = false
            })
          }

        })
      },
      async validateRequest() {
        const requestForm = document.getElementById('entryForm')
        if (requestForm.checkValidity() === false) {
          requestForm.classList.add('was-validated');
          var errorElements = document.querySelectorAll(
            ".form-control:invalid");
          $('html, body').animate({
            scrollTop: $(errorElements[0]).offset().top - 20
          }, 500);
          return false
        }
        return true
      }
    },
    //beforeRouteEnter(to, from, next) {
    //  next(vm => {
    //    if (vm.$route.params.employeeId) {
    //      if (vm.$route.params.vacationYear) {
    //        vm.vacationMaster.vacationYear = vm.$route.params.vacationYear
    //      }
    //      vm.fetchEmployeeData(vm.$route.params.employeeId)
    //    }
    //    else {
    //      vm.fetchEmployeeData(vm.currentUser.employeeId)
    //    }
    //  })
    //},
    mounted() {
      const today = new Date()
      const currentYear = today.getFullYear()
      if (today.getMonth() + 1 <= this.appSettings.vacation.lastMonthForVacationCarryOver) {
        this.additionalData.vacationYears.push(currentYear - 1)
      }
      this.additionalData.vacationYears.push(currentYear)
      if (new Date().getMonth() >= this.appSettings.vacation.nextYearScheduleStartMonth - 1) {
        this.additionalData.vacationYears.push(currentYear + 1)
      }
      if (!this.vacationMaster.vacationYear) {
        this.vacationMaster.vacationYear = currentYear
      }
    }
  }
</script>
